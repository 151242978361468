
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex';
import Card from 'primevue/card'
import CollapsibleSection from '@/components/UI/CollapsibleSection.vue';
import InputText from 'primevue/inputtext';
import TextArea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputNumber from 'primevue/inputnumber';
import ConfirmDialog from 'primevue/confirmdialog';
import Checkbox from 'primevue/checkbox';
import Utils from '@/utility/utils';
import useVuelidate from "@vuelidate/core"
import { email, helpers } from "@vuelidate/validators"
import NotificationCard from "@/components/Notification-Card.vue";
import store from '@/store';
import Dialog from 'primevue/dialog';

import SOQuotesService from '@/services/SOQuotesService';

const soquoteService = new SOQuotesService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: 'EditQuote',
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  components: {
    Card,
    CollapsibleSection,
    InputText,
    ConfirmDialog,
    TextArea,
    Dropdown,
    InputMask,
    Button,
    SplitButton,
    DataTable,
    Column,
    InputNumber,
    Dialog,
    NotificationCard,
    Checkbox,
  },

  props: {
    quote: {
      type: Object,
      default: () => ({}),
    }
  },

  created() {
    this.fetchControls({
      id: "SHIP",
      procedure: "SHIP.CONTROL",
      filename: "CONTROL",
    });
    if (this.getStoredQuote && this.currentOrder.length !== 0) {
      this.quoteInfo = this.getStoredQuote
      this.mergeParts()
    } 
    else if (this.getQuoteToEdit) {
      this.initInfoToEdit();
    }
    else {
      this.initInfo()
      this.initParts()
    }
  },
  data() {
    return {
      isLoading: false,
      showModelNumber: false,
      showPrices: false,
      saveItems: [
        {
          label: "Save & Download",
          icon: "pi pi-download",
          command: () => { this.saveDownload() }
        },
        {
          label: "Save & Share",
          icon: "pi pi-envelope",
          command: () => { this.saveShare() }
        }
      ],
      tempService: '',
      sections: {
        customerInformation: true,
        parts: true,
        goodsAndServices: true,
      },
      isPartsTableEditable: true,
      showSaveModal: false,
      showDownloadDialog: false,
      showEmailDialog:false,
      email: '',
      countryCode: '',
      quoteInfo: {
        id: '',
        name: '',
        description: '',
        myInfo: '',
        customerInfo: {
          firstName: '',
          lastName: '',
          company: '',
          email: '',
          phone: '',
          addresses: [{address: ''}],
          zip: '',
          city: '',
          state: '',
          country: '',
        },
        parts: [],
        goodOrService: []
      } as any,
      sellPriceAdjustment: {
        qty: null,
        measurement: '%',
      },
    }
  },
  validations(){
    return {
      email: {
        email: helpers.withMessage("Email not valid", email)
      }
    }
  },
  watch: {
    quoteInfo: {
      handler: function() {
        //update store
        this.setStoredQuote(this.quoteInfo)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      countriesData: 'control/getCountries',
      currentOrder: 'pos/getCurrentOrder',
      getCustomer: "customer/getCust",
      getUser: "session/getUser",
      getQuoteToEdit: "sales/getQuoteToEdit",
      getStoredQuote: "pos/getStoredQuote",
      getModules: "env/getModules",
      getSelectedCoCode: "control/getSelectedCoCode"
    }),
    getModalButtonLabel(): string {
      if (!this.showDownloadDialog && this.showEmailDialog) {
        return "Send";
      } else if (this.showDownloadDialog && !this.showEmailDialog) {
        return "Download";
      } else {
        return "";
      }
    },
    getModalTitle(): string {
      if (!this.showDownloadDialog && this.showEmailDialog) {
        return "Save & Email Quote";
      } else if (this.showDownloadDialog && !this.showEmailDialog) {
        return "Save & Download Quote";
      } else {
        return "";
      }
    },
    profitSign(): string {
      return (parseFloat(this.totalPrice) - parseFloat(this.totalCost)) < 0 ? '-' : '+'
    },
    sign(): string {
      return (this.totalQuotedPrice) < 0 ? '-' : '+'
    },
    totalCost(): string {
      let total = 0
      this.quoteInfo.parts.forEach((part: any) => {
        total += (parseFloat(part.currentCost) || 0) * parseFloat(part.qty)
      });
      return total.toFixed(2)
    },
    totalPrice(): string {
      let total = 0
      this.quoteInfo.parts.forEach((part: any) => {
        total += (parseFloat(part.customerSellPrice) || 0) * parseFloat(part.qty)
      });
      return total.toFixed(2)
    },
    totalQuotedPrice(): number {
      let total = parseFloat(this.totalPrice)
      this.quoteInfo.goodOrService.forEach((service: any) => {
        total += parseFloat(service.charge) || 0
      });
      return total
    }
  },

  methods: {
    ...mapActions({
      fetchControls: 'control/fetchControls',
      clearQuoteToEdit: 'sales/clearQuoteToEdit',
      clearOrder: "pos/clearOrder",
      fetchQuotePDF: "sales/fetchQuotePDF",
      setStoredQuote: "pos/setStoredQuote"
    }),
    onServiceDropdownKeydown(index: number, event: any) {
      event.stopPropagation()
      if (event.key === 'Enter') {
        this.quoteInfo.goodOrService[index].name = this.tempService
      }
    },
    saveDownload() {
      this.showDownloadDialog = true; 
      this.showEmailDialog = false; 
      this.showSaveModal = true;
    },
    saveShare() {
      this.showEmailDialog = true; 
      this.showDownloadDialog = false; 
      this.showSaveModal = true;
    },
    initInfo() {
      //init contact info
      this.quoteInfo.name = 'Quote ' + Utils.formatDate(new Date())
      this.quoteInfo.myInfo = this.getUser.first_name + ' ' + this.getUser.last_name + '\n'
      if(this.getUser.telephone_items) {
        this.quoteInfo.myInfo += this.getUser.telephone_items[0]?.telephone + '\n'
      }
      if(this.getUser.email_address_items  ) {
        this.quoteInfo.myInfo += this.getUser.email_address_items[0]?.email_address || '';
      }
    },
    initInfoToEdit() {
      this.quoteInfo.id = this.getQuoteToEdit.id;
      this.quoteInfo.name = this.getQuoteToEdit.quote_name || '';
      this.quoteInfo.description = this.getQuoteToEdit.quote_desc || '';
      this.quoteInfo.myInfo = this.getQuoteToEdit.notes || '';
      this.quoteInfo.customerInfo.phone = this.getQuoteToEdit.phone || '';
      this.quoteInfo.customerInfo.email = this.getQuoteToEdit.email || '';
      this.quoteInfo.customerInfo.firstName = this.getQuoteToEdit.quoted_for_first_name || '';
      this.quoteInfo.customerInfo.lastName = this.getQuoteToEdit.quoted_for_last_name || '';
      this.quoteInfo.customerInfo.company = this.getQuoteToEdit.quoted_for_company || '';
      this.quoteInfo.customerInfo.email = this.getQuoteToEdit.quoted_for_email || '';
      this.quoteInfo.customerInfo.phone = this.getQuoteToEdit.quoted_for_phone || '';
      this.quoteInfo.customerInfo.city = this.getQuoteToEdit.quoted_for_city || '';
      this.quoteInfo.customerInfo.state = this.getQuoteToEdit.quoted_for_state || '';
      this.quoteInfo.customerInfo.country = this.getQuoteToEdit.quoted_for_country || '';
      this.quoteInfo.customerInfo.zip = this.getQuoteToEdit.quoted_for_zip || '';
      this.quoteInfo.customerInfo.addresses = [];
      this.getQuoteToEdit.quoted_for_address_items?.forEach((item: any) => {
        this.quoteInfo.customerInfo.addresses.push({address: item.address})
      }
      )
      this.initPartsToEdit();    
    },
    initPartsToEdit() {
      this.getQuoteToEdit.li_items?.forEach((item: any) => {
        // if a line item is a service, push it to the goods and services array
        if(item.custom_fields?.service === 'Y'){
          this.quoteInfo.goodOrService.push({
            name: item.custom_fields?.service_type,
            notes: item.custom_fields?.service_note,
            charge: item.qty_items[0]?.price || 0,
           })
        }
        else {
        this.quoteInfo.parts.push({
          partNumber: item.part,
          partalt: item.custom_fields?.altcode1 ,
          qty: item.qty_items[0]?.qty ? parseFloat(item.qty_items[0]?.qty).toFixed(2) : 0,
          currentCost: item.cost || 0,
          customerSellPrice: item.qty_items[0]?.price || 0,
          sellPriceAdjustment: {
            qty: item.qty_items[0]?.markup,
            measurement: item.qty_items[0]?.markup_type === '' ? '%' : '$'
          },
          desc: item.wrap_desc
        })
      }
      })
    
    },
    mergeParts() {
      this.currentOrder.forEach((part:any) => {
        let index = this.quoteInfo.parts.findIndex((e: any) => e.partNumber === part.part_number)
        if (index > -1) {
          this.quoteInfo.parts[index].qty = part.quantity
        } else {
          this.quoteInfo.parts.push({
            partNumber: part.part_number,
            partalt:  part.custom_fields?.altcode1 || part.altcode1,
            qty: part.quantity,
            currentCost: part.price || 0,
            customerSellPrice: part.price || 0,
            sellPriceAdjustment: {
              qty: null,
              measurement: '$'
            },
          })
        }
      });
      this.quoteInfo.parts = this.quoteInfo.parts.filter((quotePart : any) => this.currentOrder.some((orderPart: any) => orderPart.part_number === quotePart.partNumber));
    },
    initParts() {
      this.currentOrder.forEach((part:any) => {
        this.quoteInfo.parts.push({
          partNumber: part.part_number,
          partalt:  part.custom_fields?.altcode1 || part.altcode1,
            qty: part.quantity,
            currentCost: part.price || 0,
            customerSellPrice: part.price || 0,
            sellPriceAdjustment: {
              qty: null,
              measurement: '$'
            },
        })
      });
    },
    toggleSectionIcon(section: "customerInformation" | "parts" | "goodsAndServices") {
      this.sections[section] = !this.sections[section];
    },
    getCountries() {
      const countryOptions = this.countriesData?.map(
        (element: any) => element.country
      );
      return countryOptions;
    },
    getCountryCode(event: any) {
      for (let i = 0; i < this.countriesData.length; i++) {
        if (event === this.countriesData[i].country) {
          this.countryCode = this.countriesData[i].country_code;
          return this.countryCode;
        }
      }
    },
    addAddressLine(index: number) {
      this.quoteInfo.customerInfo.addresses.splice(index + 1, 0, {address: ''});
    },
    onInputSellPrice(index: any, event: any) {
      let newValue = event.value
      this.quoteInfo.parts[index].sellPriceAdjustment.measurement = '%'
      this.quoteInfo.parts[index].sellPriceAdjustment.qty = (parseFloat(newValue) - parseFloat(this.quoteInfo.parts[index].currentCost))/parseFloat(this.quoteInfo.parts[index].currentCost)/.01
    },
    onInputQty(index: any, event: any) {
      let newValue = event.value
      if(this.quoteInfo.parts[index].sellPriceAdjustment.measurement === '$')
        this.quoteInfo.parts[index].customerSellPrice = (parseFloat(this.quoteInfo.parts[index].currentCost) + parseFloat(newValue)).toFixed(2)
      else
        this.quoteInfo.parts[index].customerSellPrice = parseFloat(this.quoteInfo.parts[index].currentCost) + parseFloat(this.quoteInfo.parts[index].currentCost) * parseFloat(newValue) *.01
    },
    onInputMeasurement(index: any, event: any) {
      let newValue = event.value
      if(newValue === '$') 
        this.quoteInfo.parts[index].customerSellPrice = (parseFloat(this.quoteInfo.parts[index].currentCost) + parseFloat(this.quoteInfo.parts[index].sellPriceAdjustment.qty)).toFixed(2)
      else
        this.quoteInfo.parts[index].customerSellPrice = parseFloat(this.quoteInfo.parts[index].currentCost) + parseFloat(this.quoteInfo.parts[index].currentCost) * parseFloat(this.quoteInfo.parts[index].sellPriceAdjustment.qty) * .01
    },
    deleteAddressLine(index: number) {
      this.quoteInfo.customerInfo.addresses.splice(index, 1);
      if(this.quoteInfo.customerInfo.addresses.length == 0) {
        this.quoteInfo.customerInfo.addresses.push({address: ""});
      }
    },
    deleteGoodOrService(index: number) {
      this.quoteInfo.goodOrService.splice(index, 1);
    },
    handleSellPriceAdjustmentApplyToAll(event: any) {
      event.stopPropagation();
      if(this.isPartsTableEditable) {
        this.confirmApplyToAll()
      }
      
    },
    handleRemovePart(index: number) {
      this.quoteInfo.parts.splice(index, 1);
    },
    formatPrice(amount: number | string) {
      return Utils.formatPrice(amount);
    },  
    confirmApplyToAll() {
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Apply to All',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.quoteInfo.parts.forEach((part: any) => {
            part.sellPriceAdjustment ={ ...this.sellPriceAdjustment};
            part.customerSellPrice = part.sellPriceAdjustment.measurement === '$' ? parseFloat(part.currentCost) + parseFloat(part.sellPriceAdjustment.qty) : parseFloat(part.currentCost) + (parseFloat(part.sellPriceAdjustment.qty) * parseFloat(part.currentCost) * .01)
          })
        },
      });
    },
    addService() {
      this.quoteInfo.goodOrService.push({
        charge: '',
        notes: '',
        name: ''
      })
    },
    createQuote() {
      let line_items = [] as any
      let saleId = ''
      this.quoteInfo.parts.forEach((part: any) => {
        line_items.push({
          li: (line_items.length + 1).toString(),
          part: part.partNumber,
          qty_items: [{
            price: part.customerSellPrice.toString(),
            markup: part.sellPriceAdjustment.measurement === '$' ? (parseFloat(part.customerSellPrice) - parseFloat(part.currentCost))/parseFloat(part.currentCost)/.01 : parseFloat(part.sellPriceAdjustment.qty).toFixed(2),
            markup_type: part.sellPriceAdjustment.measurement,
            qty: part.qty
          }],
          cost: part.currentCost.toString(),
          wrap_desc: this.currentOrder[line_items.length].desc,
          sched_dates_items: [
            {
              sched_date: Utils.formatDate(new Date()),
              sched_qty: (part.qty).toString(),
            },
          ],
        })
      });
      this.quoteInfo.goodOrService.forEach((service: any) => {
        if(service.charge !== '') {
          line_items.push({
            li: (line_items.length + 1).toString(),
            qty_items: [{
              price: service.charge.toString(),
              qty: '1'
            }],
            li_notes: service.note,
            wrap_desc: service.name,
            custom_fields: {
              service: 'Y',
              service_type: service.name,
              service_note: service.notes
            }
          })
        }
      });
      let address_items = [] as any
      this.quoteInfo.customerInfo.addresses.forEach((addressLine:any) => {
        address_items.push ({
          address : addressLine.address
        })
      });
      return new Promise((resolve, reject) => { 
        soquoteService.postSOQuote({
          date: Utils.formatDate(Date()),
          customer: this.getCustomer.cust_id,
          name: this.getCustomer.name,
          cust_code: this.getCustomer.code,
          contact: this.getUser.contact_id,
          quoted_by: this.getUser.first_name + ' ' + this.getUser.last_name,
          notes: this.quoteInfo.myInfo,
          quote_name: this.quoteInfo.name,
          quote_desc: this.quoteInfo.description,
          quoted_for_address_items: address_items,
          quoted_for_first_name : this.quoteInfo.customerInfo.firstName,
          quoted_for_last_name : this.quoteInfo.customerInfo.lastName,
          quoted_for_company : this.quoteInfo.customerInfo.company,
          quoted_for_email : this.quoteInfo.customerInfo.email,
          quoted_for_phone : this.quoteInfo.customerInfo.phone,
          quoted_for_zip : this.quoteInfo.customerInfo.zip,
          quoted_for_city : this.quoteInfo.customerInfo.city,
          quoted_for_state : this.quoteInfo.customerInfo.state,
          quoted_for_country : this.quoteInfo.customerInfo.country,
          li_items: line_items,
          co_code: this.getSelectedCoCode,
        })
        .then((response) => {
          saleId = (response as any).data.recordId;
          const notification = {
            message: `Successfully Created Quote #${saleId}.`,
            type: "success",
          };
          store.dispatch("notification/add", notification);
          resolve(saleId); // Resolve the promise with the saleId
        })
        .catch((error) => {
          const notification = {
            message: error?.response?.data.error || "Error processing quote",
            type: "error",
          };
          store.dispatch("notification/add", notification);
          reject(error); // Reject the promise with the error
        });
      });
    },
    handleCloseModal() {
      this.showSaveModal = false;
      this.showDownloadDialog = false;
      this.showEmailDialog = false;
      this.showModelNumber = false;
      this.showPrices = false;
    },
    async handleShareOrDownload() {
      if (this.showDownloadDialog && !this.showEmailDialog) {
        await this.saveAndDownload();
      } else if (!this.showDownloadDialog && this.showEmailDialog) {
        await this.saveAndEmail();
      }
    },
    async saveAndDownload (){
      this.isLoading = true;
      try {
        let saleId = ''
        if ( this.getQuoteToEdit) {
          saleId = await this.updateQuote() as string// Wait for updateQuote to complete

        } else {
          saleId = await this.createQuote() as string// Wait for createQuote to complete
        }
        const notification = {
          message: `Opening Quote #${saleId} PDF.`,
          type: "success",
        };
        store.dispatch("notification/add", notification);
        
        let modelNo = 'N'
        let prices = 'N'
        if(this.showModelNumber) {
          modelNo = 'Y'
        }
        if(this.showPrices) {
          prices = 'Y'
        }
        await this.fetchQuotePDF({
         recordId: saleId,
         modelNo,
         prices,
        })
      }
      finally {
        this.isLoading = false;
        this.handleCloseModal()
        this.email = "";
        this.clearQuoteToEdit()
        this.setStoredQuote(null)
        this.clearOrder()
        if(this.getModules.includes('Quotes')) {
          this.$router.push('/quotes')
        } else {
          this.$router.push('/sales')
        }
          
      }
    },
    async saveAndEmail() {
      this.isLoading = true;
      try {
        let saleId = '';
        if ( this.getQuoteToEdit) {
          saleId = await this.updateQuote() as string// Wait for updateQuote to complete

        } else {
          saleId = await this.createQuote() as string// Wait for createQuote to complete
        }
        let modelNo = 'N'
        let prices = 'N'
        if(this.showModelNumber) {
          modelNo = 'Y'
        }
        if(this.showPrices) {
          prices = 'Y'
        }
        const response = await soquoteService.SOQuotePdf(saleId, this.email, modelNo, prices);
    
        if (response === 'success') {
          store.dispatch("notification/add", {
            message: `Quote has been emailed successfully`,
            type: "success",
          });
        } else {
          store.dispatch("notification/add", {
            message: `Quote was not sent`,
            type: "error",
          });
        }
      } catch (error: any) {
        const notification = {
          message: error?.response?.data.error || "Error processing quote",
          type: "error",
        };
        store.dispatch("notification/add", notification);
      } finally {
        this.isLoading = false;
        this.handleCloseModal()
        this.email = "";
        this.clearOrder()
        this.setStoredQuote(null)
        this.clearQuoteToEdit()
        if(this.getModules.includes("Quotes")) {
          setTimeout(() => this.$router.push('/quotes'), 1500);
        } else {
          setTimeout(() => this.$router.push('/sales'), 1500);
        }
      }
    },
    async save() {
      try {
        if (this.getQuoteToEdit) {
          await this.updateQuote()
        } else { 
          await this.createQuote();
        }

        this.clearQuoteToEdit()
        this.setStoredQuote(null)
        this.clearOrder()
        if(this.getModules.includes("Quotes")) {
          setTimeout(() => this.$router.push('/quotes'), 1500);
        } else {
          setTimeout(() => this.$router.push('/sales'), 1500);
        }
      }
      catch (error: any) {
        console.log(error)
      }
      
    },
    updateQuote() {
      let line_items = [] as any
      let saleId = ''
      this.quoteInfo.parts.forEach((part: any) => {
        line_items.push({
          li: (line_items.length + 1).toString(),
          part: part.partNumber,
          qty_items: [{
            price: part.customerSellPrice.toString(),
            markup: part.sellPriceAdjustment.measurement === '$' ? (parseFloat(part.customerSellPrice) - parseFloat(part.currentCost))/parseFloat(part.currentCost)/.01 : parseFloat(part.sellPriceAdjustment.qty).toFixed(2),
            markup_type: part.sellPriceAdjustment.measurement,
            qty: part.qty
          }],
          cost: part.currentCost.toString(),
          wrap_desc: part.desc,
          sched_dates_items: [
            {
              sched_date: Utils.formatDate(new Date()),
              sched_qty: (part.qty).toString(),
            },
          ],
        })
      });
      this.quoteInfo.goodOrService.forEach((service: any) => {
        if(service.charge !== '') {
          line_items.push({
            li: (line_items.length + 1).toString(),
            qty_items: [{
              price: service.charge.toString(),
              qty: '1'
            }],
            li_notes: service.note,
            wrap_desc: service.name,
            custom_fields: {
              service: 'Y',
              service_type: service.name,
              service_note: service.notes
            }
              })
        }
      });

      let address_items = [] as any
      this.quoteInfo.customerInfo.addresses.forEach((addressLine:any) => {
        address_items.push ({
          address : addressLine.address
        })
      });

      return new Promise((resolve, reject) => {
        const newQuote = JSON.parse(JSON.stringify(this.getQuoteToEdit))
        newQuote.quoted_by = this.getUser.first_name + ' ' + this.getUser.last_name
        newQuote.notes = this.quoteInfo.myInfo
        newQuote.quote_name = this.quoteInfo.name
        newQuote.quote_desc = this.quoteInfo.description
        newQuote.quoted_for_address_items = address_items
        newQuote.quoted_for_first_name = this.quoteInfo.customerInfo.firstName
        newQuote.quoted_for_last_name = this.quoteInfo.customerInfo.lastName
        newQuote.quoted_for_company = this.quoteInfo.customerInfo.company
        newQuote.quoted_for_email = this.quoteInfo.customerInfo.email
        newQuote.quoted_for_phone = this.quoteInfo.customerInfo.phone
        newQuote.quoted_for_zip = this.quoteInfo.customerInfo.zip
        newQuote.quoted_for_city = this.quoteInfo.customerInfo.city
        newQuote.quoted_for_state = this.quoteInfo.customerInfo.state
        newQuote.quoted_for_country = this.quoteInfo.customerInfo.country
        newQuote.li_items = line_items
        soquoteService.putSOQuote({
          quoteId: this.getQuoteToEdit.id, 
          newQuote: newQuote,
          oldQuote: this.getQuoteToEdit
        })
          .then((response) => {
            saleId = (response as any).data.recordId;
            const notification = {
              message: `Successfully Updated Quote #${saleId}.`,
              type: "success",
            };
            store.dispatch("notification/add", notification);
            resolve(saleId); // Resolve the promise with the saleId
          }).catch((error) => {
            const notification = {
              message: error?.response?.data.error || "Error processing quote",
              type: "error",
            };
            store.dispatch("notification/add", notification);
            reject(error); // Reject the promise with the error
          });
        })
      },
      onCancel() {
        if(this.getQuoteToEdit) {
          this.clearQuoteToEdit();
          this.setStoredQuote(null)
          this.$router.push('/quotes')
        } else {
          this.setStoredQuote(null)
          this.clearOrder()
          this.$router.push('/sales')
        }
      },
    }
  })
          
